import request from '@/utils/requestV2'
const qs = require('qs')

// 通过站点id获取商家集合分页
export function getBusinessPage (data) {
  return request({
    url: '/ooh-dm/v1/business/listBusinessInfoPage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分类列表
export function listBusinessType () {
  return request({
    url: '/ooh-dm/v1/business/listBusinessType',
    method: 'get'
  })
}

// 商家列表
export function listBusinessInfoPage (data) {
  return request({
    url: '/ooh-dm/v1/business/listBusinessInfoPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
