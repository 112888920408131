<style scoped>
.map-container {width:100%; }
</style>

<template>
    <div>
        <div id="todMap" class="map-container"></div>
    </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { listBusinessInfoPage } from '@/api/dmp/business'
import { getStorage } from '@/utils/storage'

export default {
  data () {
    return {
      amapObject: null, // 地图对象
      needleMarkers: [],
      needleMarkerLable: null,
      needleMarkerStyle: {
        url: require('@/assets/images/needle.png'),
        anchor: new AMap.Pixel(0, 0),
        size: new AMap.Size(13, 25)
      },

      publisherCityCode: this.$store.getters.token.userInfo.publishers[0].cityCode,
      mapCityCenter: [
        { cityCode: '350200', cityName: '厦门市', center: [118.103886046, 24.4892306125] },
        { cityCode: '370200', cityName: '青岛市', center: [120.383414, 36.075069] },
        { cityCode: '110100', cityName: '北京市', center: [116.395645038, 39.9299857781] },
        { cityCode: '330200', cityName: '宁波市', center: [121.579005973, 29.8852589659] }
      ],

      businessQuery: {
        cityCode: this.$store.getters.token.userInfo.publishers[0].cityCode,
        stationId: null,
        distance: 500,
        bigTypeCode: null,
        middleTypeCode: null,
        pageNumber: 1,
        pageSize: 10000
      }
    }
  },
  mounted () {
    document.getElementById('todMap').style.height = document.body.clientHeight + 'px'
    this.initMap()
  },
  methods: {
    initMap () {
      // 获取主题皮肤，设置地图底色
      let mapStyleUrl = ''
      if (this.themeIsDark) {
        mapStyleUrl = 'amap://styles/0cb5926be640697061b64db1faf4306e'
      }

      lazyAMapApiLoaderInstance.load().then(() => {
        // your code ...
        const center = this.mapCityCenter.find(x => x.cityCode === this.publisherCityCode).center
        this.amapObject = new AMap.Map('todMap', {
          resizeEnable: true,
          center: new AMap.LngLat(center[0], center[1]),
          mapStyle: mapStyleUrl,
          zoom: 12,
          zooms: [10, 16]
        })
      })
    },
    loadMarkerData () {
      listBusinessInfoPage(this.businessQuery).then(res => {
        const markerData = []

        res.list.forEach(element => {
          markerData.push({
            lnglat: [parseFloat(element.longitude), parseFloat(element.latitude)],
            name: element.businessName
          })
        })

        this.addMassMarks(markerData)
      })
    },
    addMassMarks (markerData) {
      var massMarks = new AMap.MassMarks(markerData, {
        zIndex: 999, // 海量点图层叠加的顺序
        zooms: [10, 16], // 在指定地图缩放级别范围内展示海量点图层
        style: this.needleMarkerStyle // 设置样式对象
      })

      const that = this

      massMarks.on('mouseover', function (e) {
        that.needleMarkerLable = new AMap.Marker({ content: ' ', map: that.amapObject })
        that.needleMarkerLable.setPosition(e.data.lnglat)
        that.needleMarkerLable.setLabel({ content: e.data.name })
      })
      massMarks.on('mouseout', function (e) {
        that.amapObject.remove(that.needleMarkerLable)
      })

      // 将海量点添加至地图实例
      massMarks.setMap(this.amapObject)
    },
    clearPageMarker () {
      if (this.amapObject) {
        // 清空全部标注
        this.amapObject.clearMap()
      } else {
        setTimeout(() => {
          this.clearPageMarker()
        }, 1000)
      }
    }
  },
  computed: {
    updateSearch () {
      return this.$store.state.tod.updateSearch
    },
    searchCondition () {
      return this.$store.state.tod.searchCondition
    },
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  watch: {
    updateSearch () {
      if (!this.searchCondition.distance) { return }

      this.clearPageMarker()
      this.needleMarker = []
      this.businessQuery.pageNumber = 1
      this.businessQuery.stationId = this.searchCondition.stationId
      this.businessQuery.distance = this.searchCondition.distance
      // 设置分类条件
      if (this.searchCondition.bigTypeCode) {
        this.businessQuery.bigTypeCode = this.searchCondition.bigTypeCode
        this.businessQuery.middleTypeCode = null
      } else {
        this.businessQuery.bigTypeCode = null
        this.businessQuery.middleTypeCode = this.searchCondition.middleTypeCode
      }

      this.loadMarkerData()
    }
  }
}
</script>
